import { Box } from "@material-ui/core";
import { useHelpStyles } from "../../../assets/styles/Help.styles";

function CorporateValues() {
    const classes = useHelpStyles();

    return (
        <>
            <Box className={`${classes.header3} ${classes.mediumText} `}>OUR CORPORATE VALUES</Box>
            <br/>
            <Box className={`${classes.helpText} ${classes.regularText}`}>
                <span className={classes.mediumText}>Accountability</span>
                <br/><br/>
                We accept responsibility, assume ownership and take full responsibility for all of our actions, in our decisions or behaviors in the delivery of services and management of resources, in our public and private spheres, regardless of our level in the organization.
                <br/><br/>
                <span className={classes.mediumText}>Caring</span>
                <br/><br/>
                We identify ourselves with the Company; giving total dedication and commitment to it, exemplified by performing our very best in every endeavor; with great concern for the Company, its resources and its stakeholders; customers, employees, investors and the communities they serve.
                <br/><br/>
                <span className={classes.mediumText}>Excellence</span>
                <br/><br/>
                We strive to create and enhance value for all our stakeholders, customers, employees, investors, and the communities we serve. We proactively seek and implement opportunities that drive and sustain higher levels of organizational performance and growth, cost-effectiveness and efficient delivery of services.
            </Box>
        </>
    );
}

export default CorporateValues;