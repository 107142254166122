import { Box } from "@material-ui/core";
import { useHelpStyles } from "../../../assets/styles/Help.styles";

function Direction() {
    const classes = useHelpStyles();

    return (
        <>
            <Box className={`${classes.header3} ${classes.mediumText} `}>OUR DIRECTION</Box>
            <br/>
            <Box className={`${classes.helpText} ${classes.regularText}`}>
                <span className={classes.mediumText}>Our Vision:</span>
                <br/><br/>
                BAYAD CENTER at the heart of every home and in every community.
                <br/><br/>
                <span className={classes.mediumText}>Our Mission:</span>
                <br/><br/>
                We enhance the quality of life by bridging people and businesses through convenient and reliable products and services.
            </Box>
        </>
    );
}

export default Direction;