import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Paper } from '@material-ui/core';
import './Navbar.css';
import BillsPayGuide from '../BillsPayGuide/BillsPayGuide';
import ContactUs from '../ContactUs/ContactUs';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import WelcomeToBFA from '../WelcomeToBFA/WelcomeToBFA';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 5px #EBEBEB',
      borderRadius: '10px'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      borderRadius:'15%'
    }
  },
  root: {
    flexGrow: 1,
    display: 'flex',
    height: '100%'
  }, 
  tabs: {
    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start"
    }
  }
}));

export default function VerticalTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, index) => {
    setValue(index);
  };
  var tabClass = classes.tabs;
  tabClass += ' helpNavbar';

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xl={3} lg={3}>
          <Paper style={{padding:'20px 0px'}}>
            {/* <Box
              display="flex" 
            >
              <Box m="auto">
                <SearchBar />
              </Box>
            </Box> */}
            <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs"
            className={tabClass}
            TabIndicatorProps={{
              style: {
                display: "none"
              }
              }}          
            >
              <Tab label="Welcome to Bayad FA" {...a11yProps(0)} />
              <Tab label="Terms & Conditions" {...a11yProps(1)} />
              <Tab label="Pay Bills Guide" {...a11yProps(2)} />
              <Tab label="Contact Us" {...a11yProps(3)} />
            </Tabs>
          </Paper>
        </Grid>
        
        <Grid item xl={9} lg={9}>
          <Paper style={{padding:'25px',height: '93%'}}>
            <TabPanel value={value} index={0}>
              <WelcomeToBFA />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TermsAndConditions />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <BillsPayGuide />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <ContactUs />
            </TabPanel>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}