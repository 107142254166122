import { makeStyles } from '@material-ui/core/styles';

export const useHelpStyles = makeStyles((theme) => ({
    helpText: {
        fontSize: '12px',
        letterSpacing: '0px'
    },
    header1: {
        fontSize: '18px',
        color: '#f26122',
        marginBottom: '40px'
    },
    header2: {
        fontSize: '18px',
        color: '#0076c0'
    },
    header3: {
        color: '#0076c0',
        fontSize: '18px'
    },
    regularText: {
        fontFamily: 'Poppins-Regular'
    },
    tabHeader: {
        font:'14px'
    },
    mediumText: {
        fontFamily: 'Poppins-Medium'
    },
    bold: {
        fontWeight: 'bold'
    },
    indentedText10: {
        paddingLeft: '10px',
        display:'inline-block',
    },
    indentedText20: {
        paddingLeft: '20px',
        display:'inline-block',
    },
    indentedText30: {
        paddingLeft: '30px',
        display:'inline-block',
    },
    indentedText40: {
        paddingLeft: '40px',
        display:'inline-block',
    },
    indentedText80: {
        paddingLeft: '80px',
        display:'inline-block',
    },
    indentedText120: {
        paddingLeft: '120px',
        display:'inline-block',
    }
}));