import { Box } from "@material-ui/core";
import { useHelpStyles } from "../../../assets/styles/Help.styles";

function Company() {
    const classes = useHelpStyles();

    return (
        <>
            <Box className={`${classes.header3} ${classes.mediumText} `}>OUR COMPANY</Box>
            <br/>
            <Box className={`${classes.helpText} ${classes.regularText}`}>
            BAYAD CENTER is the biggest and widest multi-channel payment platform in the Philippines. With more than 20 solid years in the outsourced payment collection industry, BAYAD CENTER is the top-of-mind, leading brand when it comes to bills payment.
            <br/><br/>
            BAYAD CENTER is a subsidiary of the Manila Electric Company (MERALCO), and a member of the MVP group of companies. 
            <br/><br/>
            As the pioneer and leader in the industry, BAYAD CENTER remains at the forefront of payments by introducing its self-service digital platforms as a commitment to making payments readily available for every Filipino anytime and anywhere. The company continues to serve the ever-evolving needs of its customers by strengthening its onsite and online presence, ensuring a seamless, more accessible financial experience for the public.
            <br/><br/>
            With over 39,000 payment touchpoints nationwide, as well as powering the bills payment feature of leading online and mobile payment applications in the country, Bayad Center has evolved from an over-the-counter payment collection service provider to what is now the biggest and widest multi-channel payment platform to date. 
            <br/><br/>
            Aside from bills payment, BAYAD CENTER offers a wide array of products and services. It also offers domestic and international remittances, insurance sales, medical reimbursement, automated teller machine (ATM) withdrawal, and loan payout, among others. 
            <br/><br/>
            Processing more than 10 million transactions in a month, BAYAD CENTER is the authority in payments and the most trusted brand by the Filipino paying public.
            </Box>
        </>
    );
}

export default Company;