import { Fab } from '@material-ui/core';
import Box from '@material-ui/core/Box'
import { useHelpStyles } from '../../assets/styles/Help.styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

function TermsAndConditions() {
    const classes = useHelpStyles();

    return (
        <div style={{maxHeight:'680px',overflowY:'scroll'}}>
            <Box id='top' className={`${classes.header1} ${classes.mediumText}`}>Terms and Conditions</Box>
            <Box className={`${classes.helpText} ${classes.regularText}`}>

                Welcome to <b>BAYAD Front-end Application</b> or in short “<b>BAYAD FA</b>” (www.bfa<b>.BAYAD</b>.com), the over-the-counter payment platform of CIS Bayad Center, Inc. (<b>"BAYAD"</b>)!
                <br/><br/> 
                These TERMS AND CONDITIONS (“T&Cs”) is a contract between you and <b>BAYAD</b> and applies to your use of and access to the <b>BAYAD FA</b> and other platforms and systems of <b>BAYAD</b> and the services associated with them (“<b>BAYAD</b> Service” or “<b>BAYAD</b> Services”).
                <br/><br/>
                By providing your personal information to <b>BAYAD</b> for the pre-registration and creation  of your user account to access <b>BAYAD FA</b> and other platforms and systems owned by <b>BAYAD</b>, you agree to comply with and be legally bound by these T&Cs, as may revised from time to time. If you do not understand or do not wish to be bound by these T&Cs, or any subsequent modification thereof, you should not access or use any portion of <b>BAYAD FA</b> and other systems and platforms of <b>BAYAD</b>.
                <br/><br/>
                You also agree to comply with the <b>BAYAD</b> Data Privacy Policy (Privacy Policy) found at www <b>.BAYAD</b>.com/home/data-privacy-policy. The Privacy Policy describes how Personal Information (as defined under the applicable laws and regulations) is collected, processed and used when a transaction is processed in <b>BAYAD FA</b>.
                <br/><br/>
                <b>BAYAD</b> may amend or modify these T&Cs from time to time. Any amendment or modification shall be posted herein. The revised version will be effective at the time of posting, unless otherwise stated. By continuing to use the <b>BAYAD FA</b> after any change to these T&Cs, you agree to abide by and be bound by those changes.
                <br/><br/>
                We may (a) modify or discontinue any portion of the <b>BAYAD FA</b> ; or (b) suspend or terminate your use of and access hereto, at any time, and from time to time, without notice to you in certain and limited circumstances described herein.
                <br/><br/>
                You agree that <b>BAYAD</b> shall not be liable to you or any third party for any modification or termination of your access to the <b>BAYAD FA</b>,.
                <br/><br/>
                Under these T&Cs, the terms  <b>"BAYAD"</b>, “we”, “us”, and “our” refer to CIS Bayad Center, Inc., together with its employees, directors, affiliates, successor and assigns. <b>BAYAD</b> is duly registered with and licensed by the Bangko Sentral ng Pilipinas (BSP) for as an Operator of a Payment System and Remittance and Transfer Company and an Issuer of electronic money.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>1. DEFINITIONS</span>
                <br/><br/>
                Definitions appearing in these T&Cs shall have the meanings ascribed to them below.
                <br/><br/> 
                “<b>BAYAD FA</b>” shall refer to <b>BAYAD</b> Front-end application or the over-the counter payment platform developed by <b>BAYAD</b> where a <b>BAYAD</b> partner can facilitate a point of sale for <b>BAYAD</b> Services as authorized in separate agreements with <b>BAYAD</b>. 
                <br/><br/>
                “<b>BAYAD</b> Services” shall refer to the products that can be availed by the <b>BAYAD</b> partner’s customer through <b>BAYAD FA</b>, such as but not limited to Bills Payment, Load, Loans etc. when a <b>BAYAD</b> partner facilitates a point of sale.  
                <br/><br/>
                “Biller” shall refer to an entity with an arrangement with <b>BAYAD</b> for the collection of the bills payment of its customers for its services through <b>BAYAD FA</b>.
                <br/><br/>
                “Bills payment”, as used herein, shall refer to a <b>BAYAD</b> Service wherein you are authorized to process a payment transaction for a Biller through the <b>BAYAD FA</b>.
                <br/><br/>
                "<b>BAYAD</b> Accredited Merchant” shall refer to a merchant entity that accepts payments for its products or service offerings using the <b>BAYAD FA</b>.
                <br/><br/>
                "<b>BAYAD</b> Center” shall refer to physical outlets and branches of <b>BAYAD</b> or those of its authorized franchisees and partners that accept Cash-in and Cash-out <b>BAYAD</b> Transactions, over-the-counter Bills Payment, e-loading and gaming pins, ticketing and booking, and microinsurance.
                <br/><br/>
                "<b>BAYAD</b> Channels” refers to the over-the-counter and or different self-service/digital and payment platforms of <b>BAYAD</b> that offer <b>BAYAD</b> Services to its customers.
                <br/><br/>
                “Payment Method” refers to the payment option chosen by a customer, be it payment in cash, payment by check, payment by credit or debit cards, or payment by <b>BAYAD</b> Wallet or other alternative fund sources. The available payment methods depends on what is allowed by the Biller.
                <br/><br/>
                “Credit/Debit Card Mode of Payment” or “Visa/Mastercard Mode of Payment” refers to the <b>BAYAD FA</b>’s ability to accept credit and debit card payments through a third-party Payment Gateway Provider.
                <br/><br/>
                “Payment Gateway Provider” refers to an entity that authorizes Credit/Debit Card Mode of Payment or direct payments processing for the <b>BAYAD FA</b>.
                <br/><br/>
                “Transaction” refers to any and all transaction wherein a customer avails of a <b>BAYAD</b> Service through the <b>BAYAD FA</b>.
                <br/><br/>
                “Transaction Amount” refers to the amount to be paid by the customer for the <b>BAYAD</b> Services availed.
                <br/><br/>
                “Convenience Fee” refers to a fixed fee imposed to the customer on top of their Transaction Amount, which serves as the fee for transacting at <b>BAYAD</b>. This amount should be part of the daily collection deposits to <b>BAYAD</b>’s depository account.
                <br/><br/>
                “<b>BAYAD FA</b> account” pre-registered and verified account that will allow a user access to <b>BAYAD FA</b> and its related <b>BAYAD</b> Services depending on the user’s assigned role.
                <br/><br/>
                “One-time password” refers to the 6-digit code sent to the user’s registered mobile number.
                <br/><br/>
                “Personal Identification Number or PIN” refers to the 6-digit PIN that is nominated by the user during account creation. 
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>2. USE OF THE <b>BAYAD FA</b></span>
                <br/><br/>
                Only companies that are duly contracted with <b>BAYAD</b> as an authorized collection partner or collection associate and allowed to process payments as part of the business engagement with <b>BAYAD</b> shall be eligible to access and use the <b>BAYAD FA</b>.
                <br/><br/>
                Once we give you access to the <b>BAYAD FA</b>, you shall allow only those employees who are of legal age (18 years old) as users of the <b>BAYAD FA</b>. If an employee of yours has been given access to the <b>BAYAD FA</b> as a user, you represent that such employee is duly authorized by you 
                <br/><br/>
                If you are an agent or representative of a business entity, you agree that you are duly authorized by such business entity to access and use <b>BAYAD FA</b>, and can legally bind you as principal to these T&Cs and all Transactions processed by such employees conducted under your username and password. Accounts owned by business entities may be subject to fees that differ from the fees applicable to personal accounts.
                <br/><br/>
                You shall not allow any third-party subcontractor to use the <b>BAYAD FA</b> on your behalf unless <b>BAYAD</b> has given its prior written consent. Once such consent is given, you agree to be responsible for the compliance of such third-party sub-contractor to these T&Cs and shall be liable for its acts and omissions.
                <br/><br/>
                You must have pre-registered and verified account for <b>BAYAD FA</b>. 
                <br/><br/>
                
                <span className={`${classes.header2} ${classes.mediumText}`}>3.	<b>BAYAD FA</b> ACCOUNT MAINTENANCE</span>
                <br/><br/>
                3.1 To access the <b>BAYAD FA</b>, each user must have a pre-registered and verified <b>BAYAD FA</b> account that has undergone <b>BAYAD</b>’s standard process for account creation. The following requirements must be accomplished
                <br/><br/>
                &emsp;&emsp;•	A System Access Request Form must be filled out by the requestor;
                <br/>
                &emsp;&emsp;•	The System Access Request Form must be submitted to <b>BAYAD</b> subject for approval.
                <br/><br/>
                Once approved by <b>BAYAD</b>, the requestor will receive an email notification on their successful account creation.
                <br/><br/>
                3.2 Only one <b>BAYAD FA</b> account shall be provided to each user. 
                <br/><br/>
                3.3 The <b>BAYAD FA</b> username is system-generated and shall be found in the success account creation e-mail sent to the registered e-mail address of the partner’s nominated teller or user of <b>BAYAD FA</b>.
                <br/><br/>
                3.4 You must inform users that they should immediately nominate a password, PIN and security questions once they receive the success account creation e-mail. Their passwords and PINs must follow the standard criteria required by <b>BAYAD</b>.
                <br/><br/>
                3.5 Their usernames, passwords, PINs and security questions should be kept confidential and should not be shared to anyone.
                <br/><br/>
                3.6 Users will be regularly asked to update your password for the safety of their <b>BAYAD FA</b> account.
                <br/><br/>
                3.7 You and your users are responsible for providing accurate account creation/registration information and for keeping your registration information up to date by notifying <b>BAYAD</b> by sending an email to helpdesk@<b>BAYAD</b>.com in the event of any changes thereto. 
                <br/><br/>
                3.8 You agree to assume full responsibility and liability for the transactions and activities performed in the <b>BAYAD FA</b> through your <b>BAYAD FA</b> Account. It is presumed that log-in is done by you; your password, PIN and security questions are only known to you thus any transaction performed under your account is concluded to be authorized by you.
                <br/><br/>
                3.9 In order to access the <b>BAYAD FA</b>, you will be required to provide certain information (such as identification or contact details) as part of the registration process for the <b>BAYAD FA</b>, or as part of your continued use of the <b>BAYAD FA</b>. You agree to immediately provide written notices to <b>BAYAD</b> in case of any change to such information. <b>BAYAD</b> shall rely on information provided as correct and you agree to indemnify <b>BAYAD</b> against any and all damages it suffers by reason of incorrect information given.
                <br/><br/>
                You shall use the <b>BAYAD FA</b> strictly for purposes authorized by <b>BAYAD</b> in writing.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>4. COMPLIANCE WITH THESE T&Cs AND APPLICABLE LAWS</span>
                <br/><br/>
                4.1 <b>Your End Users</b>
                <br/>
                You and  your end users shall comply with applicable laws, regulations, and these T&Cs.
                <br/><br/>
                4.2 <b>Compliance with applicable Law and Third-Party Rights</b>
                <br/>
                You will not use  the <b>BAYAD FA</b> to encourage or promote illegal activities or any violation of third-party rights. You will not violate any other terms of service with <b>BAYAD</b> or its affiliates.
                <br/><br/>
                4.3 <b>Permitted Access</b>
                <br/>
                You will only access (or attempt to access) the <b>BAYAD FA</b> by the means described in the <b>BAYAD FA</b>’s User Manual attached hereto as Annex “___”. 
                <br/><br/>
                4.4 <b>BAYAD FA Limitations</b>
                <br/>
                <b>BAYAD</b> enforces limits on the use of the <b>BAYAD FA</b> (e.g. limiting access of a user in the <b>BAYAD FA</b>), in our sole discretion. You agree to, and will not attempt to circumvent, such limitations. 
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>5. <b>BAYAD FA</b> END USERS AND CLIENTS </span>
                <br/><br/>
                5.1 <b>BAYAD</b> may deactivate any existing feature of the <b>BAYAD FA</b> at any time at its option. On a case-to-case basis, <b>BAYAD</b> may opt to suspend or terminate your privilege to use a particular feature, whenever it deems appropriate and necessary, particularly, but not limited to occasions when there are fraudulent transactions. In such cases, <b>BAYAD</b> may charge a fee for the reactivation of the said feature, subject to other requirements <b>BAYAD</b> may impose. The approval of any request for reactivation shall be the sole option of <b>BAYAD</b>.
                <br/><br/>
                5.2 <b>BAYAD FA End Users, Clients and Monitoring</b> 
                <br/>
                YOU AGREE THAT <b>BAYAD</b> MAY MONITOR USE OF THE <b>BAYAD FA</b> TO ENSURE QUALITY OF AND IMPROVE <b>BAYAD</b> PRODUCTS AND SERVICES, AND TO VERIFY YOUR COMPLIANCE WITH THESE T&Cs. You will not interfere with <b>BAYAD</b> monitoring and scanning and <b>BAYAD</b> may use any technical means to overcome such interference. <b>BAYAD</b> may suspend access to the <b>BAYAD FA</b> or any of its features without notice if we reasonably believe that you are in violation of the T&Cs, there are pressing security risks, vulnerability, or suspicious or fraudulent transactions.
                <br/><br/>
                Further, <b>BAYAD</b> may deactivate any existing feature of the <b>BAYAD FA</b> at any time at its option. 
                <br/><br/>
                <b>BAYAD</b> may charge a fee for the reactivation of the said feature, subject to other requirements <b>BAYAD</b> may impose. The approval of any request for reactivation shall be the sole option of <b>BAYAD</b>.
                <br/><br/>
                5.3 <b>Security</b>
                <br/>
                You will use Cyber Security standard efforts to protect user information collected by the end user, including Personal Information, from unauthorized access or use and will promptly report to us any unauthorized access or use of such information to the extent required by applicable law. 
                <br/><br/>
                5.4 <b>User Privacy</b>
                <br/>
                You will comply with all applicable privacy laws and regulations including those applying to Personal Information.  
                <br/><br/>
                Further, you agree to strictly abide by all the agreements on Data Privacy and Information Security executed between you and <b>BAYAD</b>.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>6. INTELLECTUAL PROPERTY</span>
                <br/><br/>
                <b>BAYAD</b> owns the <b>BAYAD FA</b> and your use thereof does not grant your ownership to the same. 
                <br/><br/>
                You agree  not to create an application that functions substantially the same as the <b>BAYAD FA</b> and offer it for use by third parties.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>7.	CONTENT</span>
                <br/><br/>
                The <b>BAYAD FA</b> may contain some third-party contents (such as text, electronic data, or software). These contents are the sole responsibility of the person that makes it available. <b>BAYAD</b> may review content to determine whether it is illegal or violates our policies or these T&Cs, and we may remove or refuse to display content. 
                <br/><br/>
                Content accessible through the <b>BAYAD FA</b> may be subject to intellectual property rights, and, if so, you may not use, modify or remove it except as authorized in writing by <b>BAYAD</b> or the owner of that content or are otherwise permitted by law. Access to the content provided by the <b>BAYAD FA</b> may be restricted, limited, or filtered by <b>BAYAD</b> .
                <br/><br/>
                For the sole purpose of enabling <b>BAYAD</b> to provide, secure, and improve the <b>BAYAD FA</b> (and the related <b>BAYAD</b> Service(s))   you hereby give <b>BAYAD</b> a perpetual, irrevocable, worldwide, sublicensable, royalty-free, and non-exclusive license to use content submitted, posted, or displayed to or from the <b>BAYAD FA</b>. "Use" means use, host, store, communicate, and publish. Before you submit content to the <b>BAYAD FA</b>, you shall ensure that you have the necessary rights (including the necessary rights from your end users and other data subjects and data owners) to grant us this license.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>8.	OBLIGATIONS AS TO CONTENT </span>
                <br/><br/>
                In connection with your user content, you agree to:
                <br/><br/>
                8.1 to undertake the sole responsibility and hold <b>BAYAD</b> free of liability to you or to any third party, for any content that you create or information that you upload, transmit, or display while using the <b>BAYAD FA</b>;
                <br/><br/>
                8.2 to undertake sole responsibility for the consequences of your actions and interactions using the <b>BAYAD FA</b>, and hereby stipulate admission of liability to <b>BAYAD</b> for whatever loss or damage that <b>BAYAD</b> may suffer as a consequence of your use or misuse of the <b>BAYAD FA</b>;
                <br/><br/>
                8.3 not to share, create, upload, transmit or display using the <b>BAYAD FA</b> any material, information, or content which is or may be covered by copyright, patent, trade secret, trademark, trade name, service mark or any property rights, including privacy and/or publicity rights, unless you have the necessary licenses, rights, consents, and permissions to use and to authorize <b>BAYAD</b> to use any and/or all content that you create, upload, transmit, or display using the <b>BAYAD FA</b>;
                <br/><br/>
                8.4 not to use fictitious name and concealing true name for the purpose of concealing crime, evading the execution of a judgment or causing damage;
                <br/><br/>
                8.5 not to share, create, upload, transmit or display any material, information, content which is knowingly false and/or defamatory, inaccurate, abusive, vulgar, hateful, harassing, obscene, indecent, pornographic, profane, threatening, racist, gruesome, offensive to public morals, invasive of another’s privacy, or otherwise violative of any rule, regulation, or law; contains any viruses, Trojan horses, worms, time bombs, bots, ransomware, any malware, or any computer program, code, or routine that may or is intended to damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or personal information; and/or that which promotes or abets piracy; and
                <br/><br/>
                8.6 not to engage in pyramid, ponzi or similar schemes or other damaging schemes in the future; not to start or forward chain letters; and not to conduct or aid in the conduct of surveys, contests, petitions, and the like, using the <b>BAYAD</b> Services.
                <br/>
                You represent and warrant that:
                <br/><br/>
                8.7 you own or that you have the necessary licenses, rights, consents, and permissions to use and to authorize <b>BAYAD</b> to, subject to applicable laws and regulations, use, reproduce, adapt, modify, translate, publish, publicly perform, publicly display, distribute, or transmit over public networks and in various media any and/or all content that you create, upload, transmit, or display using <b>BAYAD FA</b>, as you hereby authorize and grant <b>BAYAD</b> perpetual, irrevocable, worldwide, royalty-free and non-exclusive right, license, consent and permission to use, reproduce, adapt, modify, translate, publish, publicly perform, publicly display, distribute, or transmit over public networks and in various media any and/or all content which you may create, upload, transmit, or display using the <b>BAYAD FA</b>, in a manner <b>BAYAD</b> may deem fit and necessary, including but not limited to making such content available to a third party in compliance with a requirement of law, rule or regulation, or making such content available to any third party, including <b>BAYAD</b> affiliates and subsidiaries, for its use, reproduction, adaptation, modification, translation, publication, public performance, public display, distribution or transmission; and
                <br/><br/>
                8.8 you agree to hold <b>BAYAD</b> free from any liability, both under equity and the law, arising or that may arise out of its use of your content or the use of your content by a third party to which <b>BAYAD</b> made available your content.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>9. BILLS PAYMENT </span>
                <br/><br/>
                9.1 Users can process bills payment with Billers using the <b>BAYADFA</b>. Payment options available to process bills payment are Cash and Check. But soon, <b>BAYAD</b> will add more payment options to be offered to the customers such as Credit/debit card, and through wallets.
                <br/><br/>
                9.2 Users who process bills payment in the <b>BAYAD FA</b> are obligated to follow the standard operating procedures and biller business rules provided by <b>BAYAD</b> in processing bills payment transactions.  
                <br/><br/>
                9.3Payment of bills does not require a printed receipt from the Biller. For every bills payment Transaction, you shall enter the assigned Transaction number and Transaction Amount.
                <br/><br/>
                9.4 Upon submission of bills payment request, you authorize payment to the chosen Biller and therefore, cannot be disputed by you. 
                <br/><br/>
                9.5 Transaction limits may be assigned or changed by <b>BAYAD</b> at anytime at its option pursuant to applicable laws. Limits are applied on a per Transaction basis. Transaction limits may also vary across Billers and may change without prior or immediate notice by <b>BAYAD</b>.
                <br/><br/>
                9.6 All Transactions processed using the <b>BAYAD FA</b> shall be considered as being made under your authority.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>10.	<b>BAYAD FA</b> SECURITY</span>
                <br/><br/>
                You shall be responsible for the security of your <b>BAYAD FA</b> account, your phone’s SIM containing your mobile number registered to the <b>BAYAD FA</b>, your email address registered to the <b>BAYAD FA</b>, nominated password , PIN and security questions of your <b>BAYAD FA</b> account. 
                <br/><br/>
                In case of loss of your SIM, you shall immediately inform <b>BAYAD</b> through the <b>BAYAD</b> Helpdesk of such loss within twenty-four (24) hours thereof. You may contact <b>BAYAD</b> Helpdesk at helpdesk@<b>BAYAD.com</b> or (02) 8672-5777. Suspension of the <b>BAYAD FA</b> account shall be processed only upon proper authentication of your identity and your compliance with other requirements that may be imposed by <b>BAYAD</b>. All transactions processed prior to the report of the loss shall continue to be your liability.
                <br/><br/>
                You shall be responsible on the physical access of your machine or terminal on-site which may be a risk to unauthorized access of your <b>BAYAD FA</b> account.
                <br/><br/>
                The provisions herein apply as well to the <b>BAYAD FA</b> accounts of your users.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>11.	PROHIBITED ACTS </span>
                <br/><br/>
                In connection with your use of the <b>BAYAD FA</b> and  <b>BAYAD</b> Services, you must not:
                <br/><br/>
                11.1  incorporate any word that is defamatory, obscene or profane, or which violates any trademark, service mark, or other intellectual property rights of any third party, including that of <b>BAYAD</b> in your <b>BAYAD FA</b> account name;
                <br/><br/>
                11.2 use any trademark, trade name, service mark, or logo in a way that is likely or intended to cause confusion about the owner or authorized user of such marks, names or logo;
                <br/><br/>
                11.3 alter, modify, or cause the alteration or modification, of the <b>BAYAD FA</b>, without prior written consent from <b>BAYAD</b>;
                <br/><br/>
                11.4 use any “deep-link”, “page-scrape”, “robot”, “spider” or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any part of the <b>BAYAD FA</b>, or in any way reproduce or circumvent its structure or presentation, as well as to obtain or attempt to obtain any material, document or information through any means not purposely made available through the <b>BAYAD</b> Services;
                <br/><br/>
                11.5 gain or attempt to gain unauthorized access to any part or feature of the <b>BAYAD FA</b> or to any other system or network connected with the <b>BAYAD FA</b>;
                <br/><br/>
                11.6 restrict, prevent or prohibit any other party from using the <b>BAYAD</b> Services, including but not limited to such actions which may tend to discourage others from using the <b>BAYAD</b> Services;
                <br/><br/>
                11.7 circumvent, disable, or otherwise interfere with security-related features of the <b>BAYAD FA</b>, including those that prevent or restrict use or copying of any content, material or information available on or through the <b>BAYAD FA</b>, as well as those that enforce limitations on the use of the <b>BAYAD FA</b>;
                <br/><br/>
                11.8 probe, scan or test the vulnerability of the <b>BAYAD FA</b> or any network connected to it, and not to breach the security or authentication measures on the same;
                <br/><br/>
                11.9 reverse look-up, trace or seek to trace any information on any user of or visitor to the <b>BAYAD FA</b>, or any other customer of the <b>BAYAD FA</b> including any account maintained with the <b>BAYAD</b> Services not owned by you, to its source;
                <br/><br/>
                11.10 copy or download any material or content from or through the <b>BAYAD FA</b>, unless such copying or downloading is explicitly allowed by a visible manifestation thereof such as a “download” button or a similar link ostensibly displayed;
                <br/><br/>
                11.11 engage or attempt to engage in the use, copying, transmission, broadcast, display, distribution or sale of any of the contents, material or information available on or through the <b>BAYAD</b> Services, including user comments and the like, other than as expressly permitted herein, or as explicitly indicated in the <b>BAYAD FA</b>, including use thereof for commercial purposes;
                <br/><br/>
                11.12 use any device or routine to interfere or attempt to interfere with the proper working of the <b>BAYAD FA</b> or any transaction being conducted using the <b>BAYAD FA</b>, or with any other person’s use of the <b>BAYAD FA</b>;
                <br/><br/>
                11.13 use <b>BAYAD FA</b> for any purpose that is illegal, unlawful or prohibited by these T&Cs, or to solicit the performance of any illegal activity or other activity which infringes on the rights of <b>BAYAD</b> or others; and
                <br/><br/>
                11.14 modify, rent, lease, loan, sell, distribute or create derivative works based on any content, material or information, either in whole or in part, available on or through the <b>BAYAD FA</b>, unless you have been specifically permitted to do so by <b>BAYAD</b> or by the owner of that content, material or information in a separate agreement.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>12.	RESERVED RIGHTS </span>
                <br/><br/>
                <b>BAYAD</b> reserves its rights to:
                <br/><br/>
                12.1 deny access to and / or discontinue the <b>BAYAD</b> Services or any component thereof to anyone at any time, temporarily or permanently, without giving any reason and/or prior notice to you. You hereby irrevocably agree, affirm and warrant to hold <b>BAYAD</b> free from any liability, both under equity and the law, arising or that may arise out of any such denial of access to or the discontinuance of the <b>BAYAD</b> Services;
                <br/><br/>
                12.2 collect, screen, review, flag, filter, modify, block, refuse or remove any and/or all information provided by any user, explicitly or implicitly to and through the <b>BAYAD FA</b>. You hereby irrevocably agree, affirm and warrant to hold <b>BAYAD</b> free from any liability, both under equity and the law, arising or that may arise out of any such collection, screening, review, flagging, filtering, modification, blocking, refusal or removal of any and/or all information provided by any user to and through the third-party;
                <br/><br/>
                12.3 enhance, improve, develop and introduce new features and functionalities to the <b>BAYAD FA</b> at any time and without prior notice. You hereby understand, agree, and affirm that any such enhancement, improvement, development, new feature and/or new functionality to the <b>BAYAD FA</b> shall form part of the <b>BAYAD</b> Services and thus shall likewise be covered by these T&Cs;
                <br/><br/>
                12.4 verify, check, cross-refer, validate, and ascertain the veracity and truthfulness of all information supplied by you by acquiring, accessing, retrieving, or otherwise acquiring similar or additional information supplied by you to other third-party service providers, including, but not limited to telecommunications providers, etc.;
                <br/><br/>
                12.5 verify any activity that is done through the <b>BAYAD</b> Services, especially if it involves compliance with the Anti-Money Laundering Act or with other laws, rules, and regulations.
                <br/><br/>
                12.6 send you or cause to send you service updates and/or messages, including SMS, notifications, email and/or any data message transmission, informing you of enhancements, improvements, developments, features, functionalities, products, promotions, offers, advertisement and/or any other information relative to the <b>BAYAD FA</b> and <b>BAYAD</b> Services; and
                <br/><br/>
                12.7 set limitations to and charge fees and applicable taxes for the use of the <b>BAYAD</b> Services, at any time and without prior notice. You further understand and agree that <b>BAYAD</b> reserves the right, at its sole discretion and under no obligation, to change the applicable fees, taxes and charges levied for the use of the <b>BAYAD</b> Services, at any time and with prior written notice to you.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>13.	FRAUDULENT TRANSACTIONS </span>
                <br/><br/>
                <b>BAYAD</b> shall have the right to automatically suspend or block your <b>BAYAD FA</b> account or <b>BAYAD</b> Prefund wallet in the event that <b>BAYAD</b> has reason to believe that such account or wallet may be used for fraudulent or suspicious transactions or by an unauthorized person. <b>BAYAD</b> may, but shall not have the obligation, to inform you prior to suspending or blocking your <b>BAYAD FA</b> account or <b>BAYAD</b> Prefund Wallet pursuant to this section. You acknowledge the authority of <b>BAYAD</b> to suspend or block your <b>BAYAD FA</b> account or <b>BAYAD</b> Prefund Wallet and accordingly, you shall hold <b>BAYAD</b> free and harmless against any and all consequences of such suspension or blocking, or any loss or damage which you may suffer as a result thereof.
                <br/><br/>
                Without need to give any reason or notice, and without prejudice to other provisions hereof, <b>BAYAD</b> has the absolute discretion (a) to refuse to approve any Transaction even if there is sufficient available balance in the <b>BAYAD</b> Prefund Wallet; (b) to suspend, terminate or cancel your right to use your <b>BAYAD FA</b> account and/or <b>BAYAD</b> Prefund Wallet; (c) to increase or decrease the balance limit thereof; and/or (d) to introduce, amend, vary, restrict, terminate or withdraw the benefits, services, facilities and privileges with respect to or in connection with your <b>BAYAD FA</b> account and <b>BAYAD</b> Prefund Wallet, whether specifically relating to you or generally to all <b>BAYAD</b> channel partners.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>14.	DATA PRIVACY </span>
                <br/><br/>
                14.1 Data Collection
                <br/><br/>
                <b>BAYAD</b> shall keep all its user and customer files in strictest confidence. By providing information for the purpose of availing a <b>BAYAD FA</b> account, you expressly consent to the processing of your provided user data, as may be applicable. User Data are either Personal Information or Non-Personal Information:
                <br/><br/>
                a. Personal Information, which may include Sensitive Personal Information, is any information from which the identity of an individual can be reasonably and directly ascertained, or when put together with other information would directly and certainly identify an individual. It also includes, but is not limited to, data that would help us verify [i] your identity such as such as name, gender, date of birth, address, telephone/mobile number, email address, government identification number, proof of identification, source of income, employment, and other data points whenever applicable; [ii] the location of your device whether desktop, laptop, or mobile devices whenever you access <b>BAYAD FA</b>; and [iii] the navigation experience when accessing <b>BAYAD FA</b> to see activities done in the channels, the pages visited, and the sequence thereof.
                <br/><br/>
                b. Non-Personal Information is any information that does not identify you individually, and includes transactional, statistical, and analytical data, and anonymous and aggregate reports.
                <br/><br/>
                <b>BAYAD</b> may also request your Personal Information from time to time. Should you be unable to supply <b>BAYAD</b> with the required Personal Information, <b>BAYAD</b> may be unable to provide you with requested products and services, updates on latest offerings, and you may be unable to participate in events, promotions or other activities.
                <br/><br/>
                When you use <b>BAYAD FA</b> and electronically communicate with us, depending on <b>BAYAD</b> User settings, <b>BAYAD</b> may use <b>BAYAD</b> user cookies, web beacons, small data text files or similar technologies to identify your device and record your preferences, with your consent.
                <br/><br/>
                14.2 Use of User Data
                <br/><br/>
                Without limiting the generality of the foregoing, you consent and authorize <b>BAYAD</b> to store, process, disclose, exchange, and release the said information to its associates, affiliates, subsidiaries, officers, employees, agents, lawyers and other consultants, pre-paid/debit/credit bureaus or any such persons as <b>BAYAD</b> deems necessary, or as required by law, rule or regulation, including but not limited to [i] providing you with <b>BAYAD</b> products and services, including customer support; [ii] enhancing your experience and improve, develop and determine tailored products to meet business needs; [iii] communicating relevant products and/or advisories to you; [iv] showing you relevant ads on and off our services and measure the effectiveness and reach of ads and service; [v] Abide by any safety, security, public service or legal requirements and processes; and [vi] processing information for statistical, analytical, and research purposes.
                <br/><br/>
                <b>BAYAD</b> uses Personal Information to the extent necessary to comply with the requirements of the law and legal process, such as a court order; to comply with a legal obligation; or to prevent imminent harm to public security, safety or order. <b>BAYAD</b> uses Non-Personal Information for statistical, analytical, and research purposes to create anonymous and aggregate reports.
                <br/><br/>
                When required by our Data Privacy policy and the law, and before <b>BAYAD</b> uses or processes your Customer Data for any other purpose, <b>BAYAD</b> will ask for your consent. You, as prescribed by regulation, has the right to access, correct, object, remove and transport your information. However, you requesting to remove and or transport your information shall be tantamount to cancellation/termination of your <b>BAYAD FA</b> account.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>15.	DATA SHARING</span>
                <br/><br/>
                You hereby consent to and authorize <b>BAYAD</b>, its subsidiaries, affiliates, and/or its third party partners contracted by <b>BAYAD</b> for the purpose of conducting its operations, including but not limited to fraud and risk management, sales and marketing activities, communications relating to their products and/or services, product and system development and innovation, customer experience management and improvement, and market research, to collect and process any and all information related thereto from whatever relevant source such as, but not limited to, <b>BAYAD</b> and its subsidiaries and affiliates.
                <br/><br/>
                You hereby consent to and authorize the release by <b>BAYAD</b>, its subsidiaries and affiliates, and third party partners, of any and all information required by <b>BAYAD</b> and its third party partners such as, but not limited to, the following [i] voice calling data records; [ii] SMS sending and receiving records; [iii] Data usage records; [iv] Promo subscription and redemption records; [v] Customer relationship management records; [vi] Pre-paid load balance, top up and usage records; and [vii] Credit information.
                <br/><br/>
                For the avoidance of doubt, you hereby acknowledge and agree that <b>BAYAD</b>, its affiliates, and its third party subcontractors, may share any and all information relating to Applicant to each other for any legitimate business purpose including, but not limited to, fraud and risk management, sales and marketing activities, communications relating to their products and/or services, product and system development and innovation, customer experience management and improvement, and market research.
                <br/><br/>
                The foregoing consents and authorizations shall continue for the duration of, and shall survive the termination of, this Agreement, or any other transactions, dealings, arrangements and accounts which you may have with, or avail from, <b>BAYAD</b>.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>16.	CLOSING YOUR <b>BAYAD FA</b> ACCOUNT </span>
                <br/><br/>
                Your account and those of its users will be immediately revoked in the event that you are no longer connected to <b>BAYAD</b> or its authorized partners. The accounts of your users will also be terminated upon your endorsement to <b>BAYAD</b>. You agree to immediately endorse the termination of the <b>BAYAD FA</b> accounts of the employees and users no longer connected to you.
                <br/><br/>
                
                <span className={`${classes.header2} ${classes.mediumText}`}>17.	TERMINATION / CANCELLATION OF THE <b>BAYAD</b> SERVICES </span>
                <br/><br/>
                You hereby agree that <b>BAYAD</b> is entitled to terminate your account and those of its users immediately in the event that you are found to be in breach of any of the terms stipulated in these T&Cs. For the avoidance of doubt, the termination of these T&Cs shall not require <b>BAYAD</b> to compensate, reimburse or cover any cost incurred by you in the course of you acquiring services from a Merchant or Biller.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>18. LIMITATION ON LIABILITY </span>
                <br/><br/>
                <b>BAYAD</b> makes no warranty, express or implied, regarding the <b>BAYAD FA</b> or any <b>BAYAD</b> Service. The <b>BAYAD FA</b> and related <b>BAYAD</b> Services are offered on an “AS IS”, “AS AVAILABLE” basis without warranties of any kind, other than warranties that are incapable of exclusion, waiver or limitation under the laws applicable hereto. Without limiting the generality of the foregoing, <b>BAYAD</b> makes no warranty: (1) as to the content, quality or accuracy of data or information provided by <b>BAYAD</b> hereunder or received or transmitted using the <b>BAYAD</b> Services; (2) as to any Biller or Merchant service or product obtained using the <b>BAYAD</b> Services; (3) that the <b>BAYAD</b> Services will be uninterrupted or error-free; or (4) that any particular result or information will be obtained.
                <br/><br/>
                <b>BAYAD</b> shall not be liable for any loss, cost, compensation, damage or liability to you or third party arising from, directly or indirectly, or as a result of any or all of the following:
                <br/><br/>
                a. refusal of any Biller, <b>BAYAD</b> Accredited Merchant, bank, financial institution, merchant establishment, payment aggregator and the like to allow, accept or honor payments using <b>BAYAD FA</b>;
                <br/>
                b. failure to perform or complete any Transaction due to service/ system/ line unavailability;
                <br/>
                c. any delay, interruption or termination of a Transaction whether caused by administrative error, technical, mechanical, electrical or electronic fault or difficulty or any other reason or circumstance beyond <b>BAYAD</b>’s control (including but not limited to acts of God, strike, labor disputes, fire, disturbance, action of government, atmospheric conditions, lightning, interference or damage by third parties or any change in legislation);
                <br/>
                d. misrepresentation or fraud by or misconduct by you, a Biller, a <b>BAYAD</b> Accredited Merchant or of any third party; or
                <br/>
                e. unauthorized acts or omissions of any <b>BAYAD</b> employee or agent.
                <br/><br/>
                In the event of any action that you may file against <b>BAYAD</b>, you agree that <b>BAYAD</b>’s liability shall not exceed One Thousand Pesos (PHP 1,000.00) or the amount of the actual, direct and documented damages actually suffered by you, whichever is lower.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>19.	VENUE OF LITIGATION </span>
                <br/><br/>
                Venue of all suits shall either be at Pasig City or at any venue at the exclusive option of <b>BAYAD</b> and subject to applicable laws and regulations.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>20.	NON-WAIVER OF RIGHTS </span>
                <br/><br/>
                You agree that no failure or delay by <b>BAYAD</b> in exercising any right or remedy will operate as a waiver thereof, nor will any single or partial exercise of it will preclude any other or further exercise of any right or remedy hereunder.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>21.	SEPARABILITY CLAUSE</span>
                <br/><br/>
                Should any part of these T&Cs be declared unconstitutional, illegal, void or unenforceable, the parts not affected shall remain valid and binding.
                <br/><br/>

                <span className={`${classes.header2} ${classes.mediumText}`}>22. CUSTOMER CONCERN HANDLING PROCESS </span>
                <br/><br/>
                We are committed to maintaining the highest standards of financial consumer protection. Your requests, feedback and customer needs are our priority. If you have any concerns about a procedure or have encountered a problem with our service, you may contact our Help Desk at +632 8672-5777. 
                <br/><br/>
                <span>CUSTOMER CARE CONTACT INFORMATION</span> 
                <br/><br/>
                Business Name: CIS <b>BAYAD</b> Center, INC. 
                <br/>
                Business Address: G/F, Business Solutions Center Meralco Complex, Ortigas Avenue 0300 Pasig City, Philippines 
                <br/>
                E-mail: helpdesk@<b>BAYAD</b>.com 
                <br/>
                Hotline: (02) 8672-5757; 
                <br/>
                Mobile Numbers: +63 998 998 8321; +63 908 872 4122; +63 921 567 9735
                <br/>
                Operating Hours: Monday to Friday – 6:00am – 12:00am 
                <br/><br/>
                A. TRANSACTION HANDLING 
                <br/><br/>
                All transaction concerns, whether it may come from the customer or from the branch where payment was made, shall be coordinated to <b>BAYAD</b> Helpdesk team.<br/><br/>
                <span className = {classes.indentedText40}>1.	Collection Partner sends request and required documents through email at helpdesk@<b>BAYAD</b>.com</span><br/>
                <span className = {classes.indentedText40}>2.	Contact Center agent reviews documents submitted (Editing Request Form (EDF), Letter & I.D. from the client and cancelled complete SOA or <b>BAYAD</b> Center Transaction Form (BCTF)</span><br/>
                <span className = {classes.indentedText40}>3.	Contact Center agent checks the transaction in <b>BAYAD</b> Integrated Platforms (BIP) and if uploaded already in Oracle.</span><br/>
                <span className = {classes.indentedText80}>a.	Transaction processed was not posted in real-time to the biller/partner:</span><br/>
                <span className = {classes.indentedText120}>i.	If the erroneous transaction has already been uploaded and reported the same day, transaction can still be voided provided it is not yet uploaded by ComOps to biller.</span><br/>
                <span className = {classes.indentedText120}> ii.	Helpdesk will complete a Deletion prior to biller upload form and will send to HD Team Leader for deletion of transaction within the day. Collection partner will reprocess the transaction and will update the client.</span><br/>
                <span className = {classes.indentedText80}>b.	Transaction processed was posted real-time to the biller/partner:</span><br/>
                <span className = {classes.indentedText120}>i.	Contact Center agent processes created ticket to cancel/adjust payment thru memorandum creation (Adjustment Memo) to be sent to the biller</span><br/>
                <span className = {classes.indentedText120}>ii.	CSD staff - creates memo based on the generated ticket for creation (Creation of memo is next working day)</span><br/>
                <span className = {classes.indentedText120}>iii.	CSD staff - emails created memo to the biller (Target sending within the next 48 hours)</span><br/>
                <span className = {classes.indentedText120}>iv.	Biller responses & confirms the status once cancellation/adjustment has been already done.</span><br/>
                <span className = {classes.indentedText120}>v.	CSD staff - acknowledges biller's response and communicates biller's feedback to Contact Center</span><br/>
                <span className = {classes.indentedText120}>vi.	Contact Center - provides an update to the Collection Partner about the status of the payment cancellation/adjustment if approved or not by the biller. (Collection partner is the one to inform the status to the client)</span><br/>                

                <br/><br/>
                B. REFUND AND REPUSH HANDLING
                <br/>
                <span className = {classes.indentedText40}>1. The customer shall e-mail <b>BAYAD</b> Customer Care (support@<b>BAYAD</b>.com) the following information:</span>
                <br/>
                <span className = {classes.indentedText80}>a. Screenshot from the <b>BAYAD</b> (mobile application) or Online Bills Payment Website (OBP)</span>
                <br/>
                <span className = {classes.indentedText80}>b. Screenshot from the Payment Gateway’s Email that the card has been charged</span>
                <br/>
                <span className = {classes.indentedText80}>c. Request – specify if repush or refund</span>
                <br/>
                <span className = {classes.indentedText80}>d. Channel used – specify if Name of <b>BAYAD</b> Branch/OBP/<b>BAYAD</b></span>
                <br/>
                <span className = {classes.indentedText80}>e. Complete Name used on <b>BAYAD</b> OBP or BC Mobile App</span>
                <br/>
                <span className = {classes.indentedText80}>f. Email used on <b>BAYAD</b> or OBP</span>
                <br/>
                <span className = {classes.indentedText80}>g. Mobile Number used on <b>BAYAD</b> or OBP</span>
                <br/>
                <span className = {classes.indentedText80}>h. Transaction Date</span>
                <br/>
                <span className = {classes.indentedText80}>i. Biller Name</span>
                <br/>
                <span className = {classes.indentedText80}>j. Account Number</span>
                <br/>
                <span className = {classes.indentedText80}>k. Bill Amount Transacted</span>
                <br/>
                <span className = {classes.indentedText40}>2. Customer Care Agent to acknowledge attachments and information provided by customer. Shall advise the customer I ever there are lacking details.</span>
                <br/>
                <span className = {classes.indentedText40}>3. Customer Care Agent shall include the Ticket Number on the email response with the customer</span>
                <br/>
                <span className = {classes.indentedText40}>4. Customer Care Agent shall forward the concern to channel reconciliation team</span>
                <br/>
                <span className = {classes.indentedText40}>5. Channel reconciliation team to check if the transaction is part of the settlement report of the payment gateway provider and shall inform Payments team regarding the repush/refund transaction request</span>
                <br/>
                <span className = {classes.indentedText40}>6. If for repush, payments team will forward the request to frontend provider and will seek for proof of repushing and shall inform customer care and reconciliation team of the status</span>
                <br/>
                <span className = {classes.indentedText40}>7. If for refund, Payments shall perform refund with the consideration that the transaction fee and bank fee are both non-refundable and inform customer care and reconciliation team that the refund request has already been escalated to the payment gateway provider for their action and coordination with the issuing bank.</span>
                <br/><br/>

                C. CHARGEBACK HANDLING
                <br/>
                <span className = {classes.indentedText40}>1. The customer complains to his issuing bank for disputes with supporting information and documents</span><br/>
                <span className = {classes.indentedText40}>2. Issuing bank raises the dispute to the payment gateway</span><br/>
                <span className = {classes.indentedText40}>3. Payment Gateway raises the dispute to merchant CBCI</span><br/>
                <span className = {classes.indentedText40}>4. CBCI responds to Payment Gateway if the bills payment service was delivered successfully</span><br/>
                <span className = {classes.indentedText40}>5. Payment Gateway will forward CBCI’s response to the issuing bank for investigation</span><br/>
                <span className = {classes.indentedText40}>6. Issuing bank decides if the customer is eligible with chargeback/refund considering both customer and merchant information submitted</span>
            </Box>

            <div style={{float:'right'}}>
              <Fab style={{borderRadius:'20%', backgroundColor:'#f26122', marginRight:'5px'}} href='#top' color='primary' size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon fontSize={'large'} />
              </Fab>
            </div>
        </div>
    );
}

export default TermsAndConditions;