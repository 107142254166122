import { Box } from "@material-ui/core";
import { useHelpStyles } from "../../../assets/styles/Help.styles";

function Commitment() {
    const classes = useHelpStyles();

    return (
        <>
            <Box className={`${classes.header3} ${classes.mediumText} `}>OUR COMMITMENT</Box>
            <br/>
            <Box className={`${classes.helpText} ${classes.regularText}`}>
            Our commitment here at the BAYAD CENTER is to further develop payment collection for the benefit of our biller partners, our franchisees, and our customers.
            <br/><br/>
            <span className={classes.mediumText}>Commitment to our biller partners</span>
            <br/><br/>
            For our biller partners, we commit that we shall process the bill collection entrusted to us in an efficient, reliable, and accurate manner. Partnering with the Bayad Center is like having us part of your supply chain, leveraging on Bayad Center‘s economies of scale, scope, and expertise.
            <br/><br/>
            <span className={classes.mediumText}>Commitment to our franchisees</span>
            <br/><br/>
            For our franchisees, we would like to see your growth and continued profitability as we venture into opportunities mutually beneficial to both our businesses. We will support your investments through training, marketing, and consulting activities.
            <br/><br/>
            <span className={classes.mediumText}>Commitment to our customers</span>
            <br/><br/>
            For our customers, we shall maintain the highest level of security and convenience. Paying at the Bayad Center is like paying straight to the business center or branch office of your billing company.
            <br/><br/>
            With over a decade of service, we ensure that our customers will continue to experience a payment service that is both reliable and convenient.
            <br/><br/>
            Bayad Center has been, is and will always be your dependable partner, your kapitbahay na parating handang tumulong.
            <br/><br/>
            This is our commitment.
            </Box>
        </>
    );
}

export default Commitment;