import { Box } from "@material-ui/core";
import { useHelpStyles } from "../../assets/styles/Help.styles";

function ContactUs() {
    const classes = useHelpStyles();

    return (
        <>
            <Box className={classes.header1}>Contact Us</Box>
            <Box className={classes.helpText}>
                <span className={`${classes.mediumText}`}>Office Address:</span><br/>
                <span className={classes.regularText}>CIS BAYAD CENTER, INC</span><br/>
                <span className={classes.regularText}>G/F, Business Solutions Center Meralco Complex, Ortigas Avenue 0300 Pasig City, Philippines</span><br/>
                <br/>
                <span className={`${classes.mediumText}`}>Operating Hours:</span><br/>
                <span className={classes.regularText}>Monday to Friday – 6:00am – 12:00am</span><br/>
                <br/>
                <span className={`${classes.mediumText}`}>IT Service Desk Hotline:</span><br/>
                <span className={classes.regularText}>+632 8672 5752</span><br/>
                <br/>
                <span className={`${classes.mediumText}`}>Mobile Numbers:</span><br/>
                <span className={classes.regularText}>+63 919-081-9865</span><br/>
                <br/> 
                <span className={`${classes.mediumText}`}>Email Address:</span><br/>
                <span className={classes.regularText}>itservicedesk@bayad.com</span><br/>
                <br/> 


            </Box>
        </>
    );
}

export default ContactUs;