import { Box } from "@material-ui/core";
import { useHelpStyles } from "../../assets/styles/Help.styles";

function BillsPayGuide() {
    const classes = useHelpStyles();

    return (
        <>
            <Box className={`${classes.header1} ${classes.mediumText}`}>Pay Bills Guide</Box>
            <Box className={`${classes.helpText} ${classes.regularText}`}>To start the daily operation in a specific Bayad Center branch, a customer will present bill or statement of account or Bayad Center Transaction form in case no SOA presented to a front liner. He/She will receive the payment of the customer and verify all the details including the bill presented. Mode of payment will be check first for cash, check or cash or check transactions. Analyze the bill or SOA presented by checking all the details; the biller where the transaction made, the amount to be paid by the customer whether full payment, under payment or over payment depending on the biller guidelines. Make it sure that the front liner follows the biller’s guidelines to push through the transaction.
            <br/><br/>
            The system is aligned to each biller’s procedures to lessen errors. During encoding of the details, front liner can use the bar code scanner if available. If bar code scanner is not present, he/she will encode the details manually. The payment entry of BayadFA will guide the front liner to the different shortcut keys found in the system. Make it sure that all details encoded are correct and accurate before committing of transactions. Once the transaction has been committed, it will automatically be uploaded to the biller and the system’s prefund wallet will be deducted. The front liner will validate the SOA or BCTF presented to have customer’s receipt and give exact change if available. Front liner can verify the successful transaction from the history of transactions and can generate report for documentation.
            <br/><br/>
            For the successful check transactions, prepare the check deposit slip for the generation of deposit reference number and deposit account number of billers that will be done next day operation.
            </Box>
        </>
    );
}

export default BillsPayGuide;