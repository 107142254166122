import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Commitment from './components/Commitment';
import Company from './components/Company';
import CorporateValues from './components/CorporateValues';
import Direction from './components/Direction';
import History from './components/History';
import { Fab } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useHelpStyles } from '../../assets/styles/Help.styles';

function WelcomeToBFA(props) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box p={3}>
            <Typography>{children}</Typography>
            </Box>
        )}
        </div>
    );
}
  
WelcomeToBFA.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const StyledTabs = withStyles({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      color: '#f26122',
      '& > span': {
        width: '100%',
        backgroundColor: '#f26122'
      }
    },
  })((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
  
  const StyledTab = withStyles((theme) => ({
    root: {
      '&.MuiTab-textColorInherit.Mui-selected': {
        color: '#f26122'
      },
      textTransform: 'none',
      color: 'black',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      '&:focus': {
        opacity: 1
      },
    },
  }))((props) => <Tab disableRipple {...props} />);
  
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    padding: {
      padding: theme.spacing(3),
    },
    demo1: {
      backgroundColor: theme.palette.background.paper,
    },
    demo2: {
      backgroundColor: '#2e1534',
    },
  }));
    
export default function SimpleTabs() {
    const classes = useStyles();
    const classes2 = useHelpStyles();
  
    const [value, setValue] = React.useState(0);
    
    var welcomePage;
    setTimeout(() => {
      welcomePage = document.getElementById('welcomePage');
    }, 500);

    const handleChange = (event, newValue) => {
      var offset = 100;
      switch(newValue) {
        case 0:
          var company = document.getElementById('Company');
          welcomePage.scrollTop = company.offsetTop - offset;
          break;
        case 1:
          var commitment = document.getElementById('Commitment');
          welcomePage.scrollTop = commitment.offsetTop - offset;
          break;
        case 2:
          var direction = document.getElementById('Direction');
          welcomePage.scrollTop = direction.offsetTop - offset;
          break;
        case 3:
          var corporateValues = document.getElementById('CorporateValues');
          welcomePage.scrollTop = corporateValues.offsetTop - offset;
          break;
        case 4:
          default:
          var history = document.getElementById('History');
          welcomePage.scrollTop = history.offsetTop - offset;
          break;
      }
      setValue(newValue);
    };

    return (
        <>
          <div style={{marginBottom:'20px'}}>
              <StyledTabs value={value} onChange={handleChange} aria-label="simple tabs example">
                  <StyledTab className={`${classes2.tabHeader} ${classes2.mediumText}`} label="Our Company"/>
                  <StyledTab className={`${classes2.tabHeader} ${classes2.mediumText}`} label="Our Commitment"/>
                  <StyledTab className={`${classes2.tabHeader} ${classes2.mediumText}`} label="Our Direction"/>
                  <StyledTab className={`${classes2.tabHeader} ${classes2.mediumText}`} label="Our Corporate Values"/>
                  <StyledTab className={`${classes2.tabHeader} ${classes2.mediumText}`} label="Our History"/>
              </StyledTabs>
          </div>
          <div id = 'welcomePage' className={classes.helpScroll} style={{maxHeight:'620px', overflowY:'scroll'}}>
            <div id = 'Company'>
              <Company/>
            </div>
            <br/>
            <div id = 'Commitment'>
              <Commitment/>
            </div>
            <br/>
            <div id = 'Direction'>
              <Direction/>
            </div>
            <br/>
            <div id = 'CorporateValues'>
              <CorporateValues/>
            </div>
            <br/>
            <div id = 'History'>
              <History/>
            </div>
            <div style={{float:'right'}}>
              <Fab style={{borderRadius:'20%', backgroundColor:'#f26122', marginRight:'5px'}} href='#Company' color='primary' size="small" aria-label="scroll back to top">
                <KeyboardArrowUpIcon fontSize={'large'} />
              </Fab>
            </div>
          </div>
        </>
    );
}