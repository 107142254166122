import './App.css';
import './Global.css';
import Navbar from './components/Navbar/Navbar';
import { Container } from '@material-ui/core';

function App() {
  return (
    <Container maxWidth="xl" style={{margin: 0, padding: 10, height: '100%'}}>
      <Navbar />
    </Container>
  );
}

export default App;
