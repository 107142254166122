import { Box } from "@material-ui/core";
import { useHelpStyles } from "../../../assets/styles/Help.styles";

function History() {
    const classes = useHelpStyles();
    return (
        <>
            <Box className={`${classes.header3} ${classes.mediumText} `}>OUR HISTORY</Box>
            <br/>
            <Box className={`${classes.helpText} ${classes.regularText}`}>
                <span className={classes.mediumText}>BAYAD CENTER: Blazing the Trail in the Industry</span>
                <br/><br/>
                The BAYAD CENTER business model is the first of its kind in the Philippines: concurrently providing a dependable payment collection solution to its corporate partners, creating business opportunities for its franchisees, and offering the public a reliable and convenient bills payment collection service. As the pioneer, BAYAD CENTER has defined and set the standards of the Outsourced Payment Collection Industry.
                <br/><br/>
                The BAYAD CENTER was originally known as the Payment Collection Service, a division of Corporate Information Solutions, Inc. (CIS) established in May 1997. In 2006, BAYAD CENTER has spun-off from CIS to become the CIS BAYAD CENTER, INC (CBCI). A year later, as part of its mission to be present in every possible neighborhood in the country, CBCI transitioned into a franchising organization.
                <br/><br/>
                In March 25, 2008, CBCI was awarded the “Investor in People” (IiP) certification by the People Management Association of the Philippines and the UK-based IiP Quality Centre. This coveted global recognition honors CBCI as a company that nurtures the development of its employees side by side with its own corporate growth.
                <br/><br/>
                BAYAD CENTER is now the leading brand in the over-the-counter multiple bills payment collection business. It proudly provides the public a worry-free payment collection service for organizations in the following sectors: utilities, government service, telecommunications, cable TV, Internet, insurance & pre-need, healthcare, transportation, electronic wallet, credit card, real estate, financial services, arts & entertainment, columbary, memorial park, and charities.
            </Box>
        </>
    );
}

export default History;